import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyAucfcY3pTevCmd06BTDieGrNmGE5gg1IA",
    authDomain: "porumbei-55259.firebaseapp.com",
    databaseURL: "https://porumbei-55259-default-rtdb.firebaseio.com",
    projectId: "porumbei-55259",
    storageBucket: "porumbei-55259.appspot.com",
    messagingSenderId: "523401534797",
    appId: "1:523401534797:web:d9f0b7f6a317632f502b04",
    measurementId: "G-C89C81XY7P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);