import React, { useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { collection, addDoc } from "firebase/firestore"; 
import {db} from "./firebase.js"



export default function Contact(){

   
    const [captcha, setCaptcha] = useState(true);

    const [text, setText] = useState("Trimite mesaj");


    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        subject: "",
        message: ""
    })

    function handleOnChange(value){
        setCaptcha(false);
    }


    function handleChange(event){
        const {name, phone, subject, message} = event.target
        setFormData(prevFormData => ({
            ...prevFormData,
            [event.target.name]: event.target.value
        }))
    }

    function handleSubmit(event){
        event.preventDefault();     
        try {
            const docRef = addDoc(collection(db, "mesaje"), { formData });
            setText("Va multumim pentru mesaj!");
            setCaptcha(true);
            
          } catch (e) {
            alert('Eroare: ', e);
          }
          
    }

    return(
<section id="contact" class="contact">
            <div class="container">

                <div class="section-title reveal fade-bottom" >
                    <h2>Contact</h2>
                    <p>Date de contact</p>
                </div>

                <div class="row mt-5">

                    <div class="col-lg-4 reveal fade-right">
                        <div class="info">
                            <div class="address">
                                <i class="bi bi-geo-alt"></i>
                                <h4>Locație:</h4>
                                <p>Galați</p>
                            </div>

                            <div class="email">
                                <i class="bi bi-envelope"></i>
                                <h4>Email:</h4>
                                <p>carageamarian72@yahoo.com</p>
                            </div>

                            <div class="phone">
                                <i class="bi bi-phone"></i>
                                <h4>Telefon:</h4>
                                <p>0744635351</p>
                            </div>

                        </div>

                    </div>

                    <div class="col-lg-8 mt-5 mt-lg-0 reveal fade-left">

                        <form onSubmit={handleSubmit} class="php-email-form" >
                            <h3>Întreabă/Cere o ofertă</h3>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <input type="text" name="name" class="form-control" id="name" placeholder="Nume" required onChange={handleChange}
                    value={formData.name}/>
                                </div>
                                <div class="col-md-6 form-group mt-3 mt-md-0">
                                    <input type="tel" class="form-control" name="phone" id="phone" placeholder="Telefon" required onChange={handleChange}
                    value={formData.phone} pattern="[0-9]{10}"/>
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subiect" required onChange={handleChange}
                    value={formData.subject}/>
                            </div>
                            <div class="form-group mt-3">
                                <textarea class="form-control" name="message" rows="5" placeholder="Mesaj" required onChange={handleChange}
                    value={formData.message}></textarea>
                            </div>
                            <div class="my-3">
                                <div class="loading">Loading</div>
                                <div class="error-message">Eroare, mesajul nu a fost trimis!</div>
                                <div class="sent-message">Mesajul a fost trimis, te vom contacta imediat!</div>
                            </div>
                            <div className='recaptcha'>
                            <ReCAPTCHA
                            sitekey="6LdtraQhAAAAAElpmBPmZDWegq38otVWYpSyhm_D"
                            onChange={handleOnChange}
                             />
                             </div>
                            <div class="text-center"><button type="submit" disabled={captcha}>{text}</button></div>
                        </form>

                    </div>

                </div>

            </div>
        </section>
    );
}