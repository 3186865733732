import logo from './logo.svg';
import './App.css';
import Header from './Header.js';
import Hero from './Hero.js';
import Despre from './Despre.js';
import Footer from './Footer.js';
import Servicii from './Servicii.js';
import CTA from './CTA';
import Galerie from './Galerie.js';
import Contact from './Contact.js';
import ReCAPTCHA from "react-google-recaptcha";
import React from 'react';

function App() {
    return ( 
     <>
    <Header/>
    <Hero/>
    
    <main id="main">
        <Despre/>
        <Servicii/>
        <CTA/>
        <Galerie/>
        <Contact/>
    </main>
    <Footer/>
    </> 
    );
}

export default App;
