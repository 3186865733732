import img1 from './assets/img/portfolio/porumbei-2.jpeg';
import img2 from './assets/img/portfolio/porumbei-1.jpeg';
import img3 from './assets/img/portfolio/porumbei-3.jpeg';
import img4 from './assets/img/hero2.jpeg';
import img6 from './assets/img/portfolio/porumbei-5.jpeg';
import video from './assets/img/video.mp4';
import Isotope from 'isotope-layout';
import React, { useState, useEffect } from 'react';

export default function Galerie(){

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }


  useEffect(() => {
   function updatePosition() {
    let portfolioContainer = select('.portfolio-container');
        if (portfolioContainer) {
            let portfolioIsotope = new Isotope(portfolioContainer, {
                itemSelector: '.portfolio-item',
            });

        }
   }
   if(window.screen.width > 450){
    window.addEventListener('load', updatePosition)
   }
   
  }, [])
    
    
    return (
        <section id="portfolio" class="portfolio">
        <div class="container">

            <div class="section-title reveal fade-zoom-out">
                <h2>Galerie</h2>
                <p>Porumbei si poze de la evenimente</p>
            </div>


            <div class="row portfolio-container reveal fade-left">

                <div class="col-lg-4 col-md-6 portfolio-item">
                    <div class="portfolio-img"><img src={img1} height="350px" width="90%" alt=""/></div>
                    <div class="portfolio-info">
                        <h4>Porumbei voltați</h4>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                    <div class="portfolio-img"><img src={img2} class="img-fluid" alt=""/></div>
                    <div class="portfolio-info">
                        <h4>Porumbei voltați</h4>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                    <div class="portfolio-img"><img src={img3} class="img-fluid" alt=""/></div>
                    <div class="portfolio-info">
                        <h4>Porumbei voltați</h4>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                    <div class="portfolio-img"><img src={img4} class="img-fluid" alt=""/></div>
                    <div class="portfolio-info">
                        <h4>Porumbei voiajori</h4>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                    <div class="portfolio-img"><video height="500px" width="100%" control autoPlay muted loop>
                                                <source src={video} type="video/mp4"/>
                                                </video>
                    </div>
            
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                    <div class="portfolio-img"><img src={img6} class="img-fluid" alt=""/></div>
                    <div class="portfolio-info">
                        <h4>Porumbei voiajori</h4>
                     </div>
                </div>
            </div>

        </div>
    </section>
    );
}