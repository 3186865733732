import anpc from './assets/img/anpc.png';
import SOL from './assets/img/SOL.png';
import React from 'react';
export default function Footer(){
    return(
<footer id="footer">
        <div class="container">
            <h3>PORUMBEI NUNTĂ GALAȚI</h3>
            <p>Oferim nunții tale un moment artistic de neuitat!</p>
            <div class="social-links">
            <ul class="social-list ">
            <li class="social-list__item ">
                <a class="social-list__link " href="https://www.facebook.com/caragea.nelu ">
                    <i class="fab fa-facebook "></i>
                </a>
            </li>
            <li class="social-list__item ">
                <a class="social-list__link " href="https://www.instagram.com/porumbei_nunta_galati/ ">
                    <i class="fab fa-instagram "></i>
                </a>
            </li>

            </ul>
            </div>
            <div class="SOL">
                <a href="https://anpc.ro/ce-este-sal/"><img src={anpc} class="anpc"/></a>
                <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"><img src={SOL}/></a>
            </div>
            <hr/>
            <p><small>&copy; All rights reserved by Porumbei Nuntă Galați</small></p>
            <div class="credits">
                Designed by <a href="https://www.linkedin.com/in/caragea-theodor/.com/">Caragea Theodor</a>
            </div>
        </div>
    </footer>
    );
}