import porumbei1 from './assets/img/hero1.jpeg';
import porumbei2 from './assets/img/hero3.jpeg';
import porumbei3 from './assets/img/hero2.jpeg';
import React from 'react';

export default function Hero(){
    return (
      
    <section id="hero" class="d-flex flex-column justify-content-end align-items-center">
        <div id="heroCarousel" data-bs-interval="5000" class="container carousel carousel-fade" data-bs-ride="carousel">

      <div class="carousel-item active">
        <div class="carousel-container">
          <h2 class="animate__animated animate__fadeInDown" id="hero--title">Porumbei pentru nunta ta <span>de vis!</span></h2>
          <img src={porumbei1} class="img-fluid" id="porumbei"></img>

          <p class="animate__animated fanimate__adeInUp">Porumbeii oferă un moment artistic deosebit pentru nunta ta!</p>
          <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Descoperă mai mult</a>
        </div>
      </div>

      
      <div class="carousel-item">
        <div class="carousel-container">
          <h2 class="animate__animated animate__fadeInDown" id="hero--title">Porumbei voltati</h2>
          <img src={porumbei2} class="img-fluid" id="porumbei"></img>
          <p class="animate__animated animate__fadeInUp">Aceștia stau la picioarele invitaților pe toată durata ședinței foto</p>
          <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Descoperă mai mult</a>
        </div>
      </div>

      
      <div class="carousel-item">
        <div class="carousel-container">
          <h2 class="animate__animated animate__fadeInDown" id="hero--title">Porumbei voiajori</h2>
          <img src={porumbei3} class="img-fluid" id="porumbei"></img>
          <p class="animate__animated animate__fadeInUp">Acești porumbei se țin în mănă și se aruncă la îndrumarea fotografului pentru a oferi un moment superb!</p>
          <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Descoperă mai mult</a>
        </div>
      </div>

      <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"></span>
      </a>

      <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"></span>
     </a>

    </div>

    <svg class="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
      <defs>
        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
      </defs>
      <g class="wave1">
        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"/>
      </g>
      <g class="wave2">
        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"/>
      </g>
      <g class="wave3">
        <use xlinkHref="#wave-path" x="50" y="9" fill="#fff"/>
      </g>
    </svg>

  </section>
    );
}