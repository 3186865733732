import porumbei3 from './assets/img/servicii3.jpeg';
import porumbei2 from './assets/img/servicii1.jpeg';
import porumbei1 from './assets/img/porumbei-1.jpeg';
import React from 'react';


export default function Servicii(){
    return(
        <section id="services" class="features">
            <div class="container">

<div class="section-title reveal fade-zoom-out" >
    <h2>Servicii si prețuri</h2>
    <p>Ce oferim?</p>
</div>
                <ul class="nav nav-tabs row d-flex">
                    <li class="nav-item col-md-4 col-lg-4 reveal fade-bottom">
                        <a class="nav-link active show" data-bs-toggle="tab" href="#tab-1">
                            <i class="ri-gps-line"></i>
                            <h4 class="d-none d-lg-block">Porumbei in cutie specială</h4>
                        </a>
                    </li>
                    <li class="nav-item col-md-4 col-lg-4 reveal fade-bottom" >
                        <a class="nav-link" data-bs-toggle="tab" href="#tab-2">
                            <i class="ri-body-scan-line"></i>
                            <h4 class="d-none d-lg-block">Porumbei voiajori</h4>
                        </a>
                    </li>
                    <li class="nav-item col-md-4 col-lg-4 reveal fade-bottom">
                        <a class="nav-link" data-bs-toggle="tab" href="#tab-3">
                            <i class="ri-sun-line"></i>
                            <h4 class="d-none d-lg-block">Porumbei voltați</h4>
                        </a>
                    </li>
                </ul>
            <div class="tab-content reveal fade-zoom-out">
                <div class="tab-pane active show" id="tab-1">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                            <h3>O cutie plină cu porumbei care zboară în momentul desfacerii acesteia.</h3>
                            <p class="fst-italic">
                                Cutia în formă de inimă, care simbolizează iubirea impreună cu zborul porumbeilor oferă un moment magic, plin de romantism, reprezentând un moment minunat de care toți invitații își vor aminti.                          </p>
                            <ul>
                                <li><i class="ri-check-double-line"></i> Toți porumbeii din cutie sunt voiajori.</li>
                                <li><i class="ri-check-double-line"></i> Porumbeii eliberați împărtășesc puritatea, eleganța și frumusețea lor in evenimentul dumneavoastră!</li>
                                <li><i class="ri-check-double-line"></i><strong>Prețul este de 15 lei per porumbel din cutie, la care se adaugă 50 de lei prețul cutiei. Minim 15 porumbei.</strong></li>
                            </ul>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center">
                             <img src={porumbei2} alt="" class="img-fluid" height="450px" width="80%"/>
                        </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="tab-2">
                        <div class="row">
                            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                <h3>Acești porumbei se țin în mână și zboară în momentul eliberării.</h3>
                                <p>
                                    Momentul eliberării acestor porumbei poate fi la ieșirea mirilor din biserică, la ieșirea de la Oficiul Stării Civile sau la localul unde va avea loc evenimentul!
                                </p>
                                <p class="fst-italic">
                                Porumbeii albi sunt un simbol al purității, al blândeții si al duhului divin. Se spune că aceștia își păstrează perechea pentru tot restul vieții, fiind un model de fidelitate și iubire necondiționată.
                                    </p>
                                <ul>
                                    <li><i class="ri-check-double-line"></i> Porumbeii sunt de culoare alb imaculat și sunt foarte curați și blânzi.</li>
                                    <li><i class="ri-check-double-line"></i> Oferă un moment dinamic, de neuitat, pentru miri și invitații lor.</li>
                                    <li><i class="ri-check-double-line"></i> <strong>Prețul acestor porumbei este de 15 lei per porumbel.</strong></li>                                
                                    </ul>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2 text-center">
                                <img src={porumbei3} alt="" class="img-fluid" height="450px" width="80%"/>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="tab-3">
                        <div class="row">
                            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                <h3>Acești porumbei stau la picioarele mirilor când aceștia ies din biserică.</h3>
                                <p>
                                Porumbelul alb este simbolul universal al Duhului sfânt, prezent ca atare în documentele biblice la Botezul Domnului nostru Iisus Hristos, el reprezintă totodată semnul păcii, al purificării, fericirii și al prevestirii de bine aducând în cioc o crenguță de măslin.
                                </p>
                                <ul>
                                    <li><i class="ri-check-double-line"></i> Acești porumbei sunt dresați să meargă înaintea mirilor.</li>
                                    <li><i class="ri-check-double-line"></i> Culoarea acestor porumbei este alb imaculat. Aceștia nu zboară și rămân la picioarele mirilor pe toată durata ședinței foto. </li>
                                    <li><i class="ri-check-double-line"></i> <strong>Prețul acestor porumbei este de 15 lei per porumbel.</strong></li>
                                </ul>
                              
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2 text-center">
                                <img src={porumbei1} alt="" height="450px" width="80%"/>
                            </div>
                        </div>
                    </div>
    
                </div>
       </div> 
    </section>

    );
}
