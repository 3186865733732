import React from 'react';
export default function Despre() {
    return (
        <section id="about" class="about">
        <div class="container">

            <div class="section-title reveal fade-zoom-out">
                <h2>Despre noi</h2>
                <p>Cine suntem?</p>
            </div>

            <div class="row content reveal fade-left">
                <div class="col-lg-6">
                    <p>
                        Localizați în localitatea Galați, dorim să vă oferim o nuntă ca în povesți, atât mirilor cât și invitaților acestora.
                    </p>
                    <ul>
                    <li><i class="ri-check-double-line"></i> Porumbeii pe care îi oferim sunt de culoare alb imaculat, fiind foarte curați.</li>
                        <li><i class="ri-check-double-line"></i> Porumbeii au fost folosiți ca simboluri de secole, având o simbolistică vastă!</li>
                        <li><i class="ri-check-double-line"></i> Aceștia simbolizează, printre altele, pacea, liniștea și iubirea!</li>
                    </ul>
                </div>
                <div class="col-lg-6 pt-4 pt-lg-0">
                    <p>
                        Momentul artistic al eliberării porumbeilor poate fi la ieșirea mirilor din biserică, la ieșirea de la Oficiul Stării Civile sau înainte de intrarea în localul în care va avea loc evenimentul. Momentul eliberării porumbeilor va fi ales de dumneavoastră!
                    </p>
                    <div class="d-flex justify-content-center">
                        <i class="fa-solid fa-dove fa-4x" id="porumbel"></i>
                    </div>
                </div>
            </div>

        </div>
    </section>
    );
}