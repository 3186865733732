import React from 'react';
export default function CTA(){
    return(
<section id="cta" class="cta">
            <div class="container">

                <div class="row reveal fade-zoom-out" >
                    <div class="col-lg-9 text-center text-lg-start">
                        <h3>Doresti mai multe detalii?</h3>
                        <p> Pentru mai multe detalii sau pentru orice intrebare ne puteti contacta la numarul de telefon 0744635351.</p>
                    </div>
                    <div class="col-lg-3 cta-btn-container text-center">
                        <a class="cta-btn align-middle" href="#contact">Contact</a>
                    </div>
                </div>

            </div>
        </section>
    );
}