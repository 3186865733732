import React, { useState, useEffect } from 'react';

export default function Header() {

  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })
  const [navbarOpen, setNavbarOpen] = useState(false)


  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
        return [...document.querySelectorAll(el)]
    } else {
        return document.querySelector(el)
    }
}
function handleClick(){
  setNavbarOpen(!navbarOpen);
}

/**
 * Easy event listener function
 */
const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
        if (all) {
            selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
            selectEl.addEventListener(type, listener)
        }
    }
}
const scrollto = (el) => {
  let header = select('#header')
  let offset = header.offsetHeight

  let elementPos = select(el).offsetTop
  window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
  })
}


const onscroll = (el, listener) => {
  el.addEventListener('scroll', listener)
}

  useEffect(() => {
    function toggleMenu(){
       on('click', '.mobile-nav-toggle', function(e) {
        select('#navbar').classList.toggle('navbar-mobile')
        this.classList.toggle('bi-list')
        this.classList.toggle('bi-x')
    })
        /**
     * Mobile nav dropdowns activate
     */
         on('click', '.navbar .dropdown > a', function(e) {
          if (select('#navbar').classList.contains('navbar-mobile')) {
              e.preventDefault()
              this.nextElementSibling.classList.toggle('dropdown-active')
          }
      }, true)
  
      /**
       * Scrool with ofset on links with a class name .scrollto
       */
       on('click', '.scrollto', function(e) {
        if (select(this.hash)) {
            e.preventDefault()
  
            let navbar = select('#navbar')
            if (navbar.classList.contains('navbar-mobile')) {
                navbar.classList.remove('navbar-mobile')
                let navbarToggle = select('.mobile-nav-toggle')
                navbarToggle.classList.toggle('bi-list')
                navbarToggle.classList.toggle('bi-x')
            }
            scrollto(this.hash)
        }
    }, true)
  }
  toggleMenu()
  }, [navbarOpen])

  useEffect(() => {
   function updatePosition() {
       setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })

   }
   window.addEventListener('scroll', updatePosition)
   updatePosition()
  }, [])

    return(
    <header id="header" class="fixed-top d-flex align-items-center  header-transparent ">
      {scrollPosition.scrollY > 400 && document.getElementById('header').classList.add('header-scrolled')}

    <div class="container d-flex align-items-center justify-content-between">

      <div class="logo">
        <h1><a href="index.html" className="title--page">PORUMBEI NUNTĂ GALAȚI</a></h1>
      </div>

      <nav id="navbar" class="navbar" onClick={handleClick}>
        <ul>
          <li><a class="nav-link scrollto active" href="#hero">Acasă</a></li>
          <li><a class="nav-link scrollto" href="#about">Despre noi</a></li>
          <li><a class="nav-link scrollto" href="#services">Servicii și prețuri</a></li>
          <li><a class="nav-link scrollto " href="#portfolio">Galerie</a></li>
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>

    </div>
  </header>
    );
}